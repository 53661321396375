import "./DashboardQuiz.scss";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { produce } from "immer";
import { questions } from "../../data/quiz";
import { QuizFactsInfo } from "../../data/quizFacts";

/* Components needed for the Quiz */
import QuizProgressBar from "../QuizProgressBar/QuizProgressBar";
import QuizOptionCard from "../QuizOptionCard/QuizOptionCard";
import TradeInInfoInput from "../QuizEmailInput/TradeInInfo";
import IkVehicleInput from "../IkVehicleInput/IkVehicleInput";
import SelectCars from "../SelectCars/SelectCars";
import ButtonPlain from "../ButtonPlain/ButtonPlain";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import BudgetSelector from "../BudgetSelector/BudgetSelector";
import UserDetailsForm from "../UserDetailsForm/UserDetailsForm";
import QuizFact from "../QuizFact/QuizFact";

import { useDealershipContext } from "../../contexts/dealershipContext";
import { createDealershipUser } from "../../services/dealership-user";

/* SVG needed in the dashboard quiz */
import {ReactComponent as MaeCoreLogoYellow} from '../../assets/logos/Mae-Minimized-Logo-Yellow.svg';

import {
  BUDGET_INDEX,
  NEW_USED_INDEX,
  FEATURE_SELECT_INDEX,
  PAYMENT_MODE_INDEX,
  PICK_NEW_CARS_INDEX,
  PICK_USED_CARS_INDEX,
  TRADE_IN_INDEX,
  TRADE_IN_INFO_INDEX,
  USER_DETAILS_INDEX,
  VEHICLE_ENGINE_INDEX,
  VALUE_RANKING_INDEX,
  REASON_NEW_VEHICLE_INDEX,
  VEHICLE_TYPE_INDEX,
  LIFESTYLE_INDEX,
  IKIDK_INDEX,
  IK_VEHICLE_INPUT_INDEX,
  EV_SPECIFIC_Q1_INDEX,
  EV_SPECIFIC_Q2_INDEX,
} from "../../constants/quizQuestionsIndex";
import ValueRankingComponent from "../ValueRanking/ValueRanking";

function QuizOptionCardContainer({
  quizQuestions,
  setQuizQuestions,
  currentQuestionIndex,
}) {
  const selectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const selectedOptions =
          quizQuestionsDraft[currentQuestionIndex].currSelected;
        const maxOptionLen =
          quizQuestionsDraft[currentQuestionIndex].selectionRange[1];
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptions.length >= maxOptionLen) {
          const removedOptionInfo = selectedOptions.shift();
          quizQuestionsDraft[currentQuestionIndex].options[
            removedOptionInfo
          ].selected = false;
        }
        if (
          selectedOptionsIndex === -1 &&
          selectedOptions.length < maxOptionLen
        ) {
          selectedOptions.push(optionIndex);
          quizQuestionsDraft[currentQuestionIndex].options[
            optionIndex
          ].selected = true;
        }
        return quizQuestionsDraft;
      })
    );
  };

  const unselectOption = (optionIndex) => {
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        const selectedOptions =
          quizQuestionsDraft[currentQuestionIndex].currSelected;
        const selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
        if (selectedOptionsIndex !== -1) {
          quizQuestionsDraft[currentQuestionIndex].options[
            optionIndex
          ].selected = false;
          quizQuestionsDraft[currentQuestionIndex].currSelected.splice(
            selectedOptionsIndex,
            1
          );
        }
      })
    );
  };
  return (
      <>
      {quizQuestions[currentQuestionIndex].options.map((option, index) => (
        <QuizOptionCard
          key={currentQuestionIndex * 100 + index}
          index={index}
          text={option.text}
          subtext={option.subtext}
          greyicon={option.greyicon}
          selectOption={selectOption}
          unselectOption={unselectOption}
          selected={option.selected}
          className={
            currentQuestionIndex === FEATURE_SELECT_INDEX ? "feature-card" : currentQuestionIndex === LIFESTYLE_INDEX || currentQuestionIndex === IKIDK_INDEX ? 
            "big-image" : ""
          }
        />
      ))}
    </>
  );
}

function QuestionInterfaceSelector({
  quizQuestions,
  setQuizQuestions,
  currentQuestionIndex,
}) {
  switch (currentQuestionIndex) {
    case BUDGET_INDEX:
      return (
        <BudgetSelector
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
        />
      );

    case PICK_USED_CARS_INDEX:
      return (
        <SelectCars
          key={currentQuestionIndex}
          currentQuestionIndex={currentQuestionIndex}
          setQuizQuestions={setQuizQuestions}
          quizQuestions={quizQuestions}
        />
      );

    case PICK_NEW_CARS_INDEX:
      return (
        <SelectCars
          key={currentQuestionIndex}
          currentQuestionIndex={currentQuestionIndex}
          setQuizQuestions={setQuizQuestions}
          quizQuestions={quizQuestions}
        />
      );

    case USER_DETAILS_INDEX:
      return (
        <UserDetailsForm
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
        />
      );
    
    case VALUE_RANKING_INDEX:
      return (
        <ValueRankingComponent 
        quizQuestions={quizQuestions}
        setQuizQuestions={setQuizQuestions}/>
      )

    default:
      return (
        <QuizOptionCardContainer
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          currentQuestionIndex={currentQuestionIndex}
        />
      );
  }
}

function DashboardQuiz() {
  const { dealershipName, dealershipId, userSource, clearDealershipUserSource } = useDealershipContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [minimumSelected, setMinimumSelected] = useState(false);
  const [isSingleSelectionQuestion, setIsSingleSelectionQuestion] =
    useState(false);
  const [quizQuestions, setQuizQuestions] = useState(
    JSON.parse(JSON.stringify(questions))
  );
  const quizComponentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [quizPopupOn, setQuizPopupOn] = useState(true);

  const navigate = useNavigate();

  const count = quizQuestions.length;

  const singleSelectionQuestion = () => {
    return (
      currentQuestionIndex === REASON_NEW_VEHICLE_INDEX ||
      currentQuestionIndex === VEHICLE_TYPE_INDEX ||
      currentQuestionIndex === PAYMENT_MODE_INDEX ||
      currentQuestionIndex === EV_SPECIFIC_Q1_INDEX ||
      currentQuestionIndex === EV_SPECIFIC_Q2_INDEX
    );
  };


  const countSelected = () => {
    if (currentQuestionIndex === USER_DETAILS_INDEX) {
      const userDetailQuestion = quizQuestions[USER_DETAILS_INDEX];
      if (
        userDetailQuestion.firstName &&
        userDetailQuestion.lastName &&
        userDetailQuestion.email &&
        userDetailQuestion.phoneNo &&
        userDetailQuestion.preferredMethodOfContact
      ) {
        setMinimumSelected(true);
        return;
      }
      setMinimumSelected(false);
      return;
    }

    if(currentQuestionIndex === TRADE_IN_INDEX && quizQuestions[TRADE_IN_INDEX].options[0].selected) {
      if(quizQuestions[TRADE_IN_INFO_INDEX].currSelected.length > 0) {
        setMinimumSelected(true);
      } else {
        setMinimumSelected(false);
      }
      return;
    }

    if(currentQuestionIndex === IKIDK_INDEX && quizQuestions[IKIDK_INDEX].options[0].selected) {
      if(quizQuestions[IK_VEHICLE_INPUT_INDEX].currSelected.length > 0) {
        setMinimumSelected(true);
      } else {
        setMinimumSelected(false);
      }
      return;
    }

    const minimumSelectionDone =
      quizQuestions[currentQuestionIndex].currSelected.length >=
      quizQuestions[currentQuestionIndex].selectionRange[0];
    if (
      minimumSelectionDone &&
      !quizQuestions[currentQuestionIndex].visitedOnce &&
      isSingleSelectionQuestion
    ) {
      nextQuestion();
    } else if (minimumSelectionDone) {
      setMinimumSelected(true);
    } else {
      setMinimumSelected(false);
    }
  };

  useEffect(() => {
    countSelected();
  }, [quizQuestions, currentQuestionIndex]);

  useEffect(() => {
    setIsSingleSelectionQuestion(singleSelectionQuestion());
  }, [currentQuestionIndex]);

  const nextQuestion = () => {
    console.log("next question");
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        quizQuestionsDraft[currentQuestionIndex].visitedOnce = true;
      })
    );
    if (currentQuestionIndex + 1 < count) {
      //SKIPPING RANKING QUESTION
      if (currentQuestionIndex == FEATURE_SELECT_INDEX) {
        setCurrentQuestionIndex(FEATURE_SELECT_INDEX + 2)
      }
      else if(currentQuestionIndex === IKIDK_INDEX) {
        if(quizQuestions[IKIDK_INDEX].options[0].selected) {
          if(quizQuestions[IK_VEHICLE_INPUT_INDEX].currSelected.length > 0) {
            setCurrentQuestionIndex(REASON_NEW_VEHICLE_INDEX);
          } else {
            setCurrentQuestionIndex(IKIDK_INDEX);
          }
        } else {
          setQuizQuestions(
            produce((quizQuestionsDraft) => {
              quizQuestionsDraft[IK_VEHICLE_INPUT_INDEX].currSelected = [];
              quizQuestionsDraft[IK_VEHICLE_INPUT_INDEX].vehicleInfo = "";
            })
          );
          setCurrentQuestionIndex(REASON_NEW_VEHICLE_INDEX);
        }
      } else if (currentQuestionIndex == VEHICLE_ENGINE_INDEX) {
        //Electric engine selected, index 3
        if (quizQuestions[VEHICLE_ENGINE_INDEX].currSelected.includes(3)) {
          setCurrentQuestionIndex(EV_SPECIFIC_Q1_INDEX)
        } else {
          setCurrentQuestionIndex(LIFESTYLE_INDEX)
        }
      } else if (currentQuestionIndex === TRADE_IN_INDEX) {
        if (quizQuestions[TRADE_IN_INDEX].options[0].selected) {
          if(quizQuestions[TRADE_IN_INFO_INDEX].currSelected.length > 0) {
            setCurrentQuestionIndex(PAYMENT_MODE_INDEX);
          } else {
            setCurrentQuestionIndex(TRADE_IN_INDEX);
          }
        } else {
          // setQuizQuestions(
          //   produce((quizQuestionsDraft) => {
          //     quizQuestionsDraft[TRADE_IN_INFO_INDEX].currSelected = [];
          //     quizQuestionsDraft[TRADE_IN_INFO_INDEX].make = "";
          //     quizQuestionsDraft[TRADE_IN_INFO_INDEX].model = "";
          //     quizQuestionsDraft[TRADE_IN_INFO_INDEX].year = "";
          //   })
          // );
          setCurrentQuestionIndex(PAYMENT_MODE_INDEX);
        }
      } else if (currentQuestionIndex === NEW_USED_INDEX) {
        setQuizQuestions(
              produce((quizQuestionsDraft) => {
                quizQuestionsDraft[PICK_NEW_CARS_INDEX].currSelected = [{make : "", model : ""}];
              })
            );
          setCurrentQuestionIndex(PICK_USED_CARS_INDEX);
        // const NewUsedQuizObj = quizQuestions[NEW_USED_INDEX];
        // if (NewUsedQuizObj.options[1].selected) {
        //   setQuizQuestions(
        //     produce((quizQuestionsDraft) => {
        //       quizQuestionsDraft[PICK_NEW_CARS_INDEX].currSelected = [{make : "", model : ""}];
        //     })
        //   );
        //   setCurrentQuestionIndex(PICK_USED_CARS_INDEX);
        // } else {
        //   setCurrentQuestionIndex(currentQuestionIndex + 1);
        //}
        }
       else if (currentQuestionIndex === PICK_NEW_CARS_INDEX) {
        setQuizQuestions(
          produce((quizQuestionsDraft) => {
            quizQuestionsDraft[PICK_USED_CARS_INDEX].currSelected = [{make : "", model : ""}];
          })
        );
        setCurrentQuestionIndex(TRADE_IN_INDEX);
      } else setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      submit();
    }
    // setQuizPopupOn(false);
  };

  const prevQuestion = () => {
    console.log("previous question");
    if (currentQuestionIndex - 1 >= 0) {
      //SKIPPING RANKING QUESTION
      if (currentQuestionIndex == BUDGET_INDEX) {
        setCurrentQuestionIndex(BUDGET_INDEX - 2)
      }
      else if (currentQuestionIndex == LIFESTYLE_INDEX) {
        //Electric engine selected, index 3
        if (quizQuestions[VEHICLE_ENGINE_INDEX].currSelected.includes(3)) {
          setCurrentQuestionIndex(EV_SPECIFIC_Q2_INDEX)
        } else {
          setCurrentQuestionIndex(VEHICLE_ENGINE_INDEX)
        }
      }
      else if(currentQuestionIndex === PAYMENT_MODE_INDEX) {
        setCurrentQuestionIndex(TRADE_IN_INDEX);
      } else if (currentQuestionIndex === PICK_USED_CARS_INDEX) {
        setCurrentQuestionIndex(NEW_USED_INDEX);
      } else if (currentQuestionIndex === TRADE_IN_INDEX) {
        const NewUsedQuizObj = quizQuestions[NEW_USED_INDEX];
        if (
          NewUsedQuizObj.options[0].selected &&
          !NewUsedQuizObj.options[1].selected
        ) {
          setCurrentQuestionIndex(PICK_NEW_CARS_INDEX);
        } else {
          setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
      } else if(currentQuestionIndex === REASON_NEW_VEHICLE_INDEX) {
        setCurrentQuestionIndex(IKIDK_INDEX);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    } else {
      navigate(`/${dealershipId}`);
    }
  };

  const submit = async () => {
    setIsLoading(true);

    console.log(quizQuestions, "quizQuestions");

    const quizSelections = [];
    let personalInformation = {};


    console.log("entering quiz selections");

    for (let i = 0; i < count; i++) {
      var selectedOptions = [];
      if (i === FEATURE_SELECT_INDEX) {
        const featureOptions = {};

        quizQuestions[FEATURE_SELECT_INDEX].options.forEach((option) => {
          if (option.field) {
            featureOptions[option.field] = option.selected;
          }
        });

        quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions: [featureOptions],
        });
      } else if (
        i === BUDGET_INDEX
      ) {
        const budgetType = quizQuestions[BUDGET_INDEX].monthlyBudget ? "monthly" : "range";
        quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions: [...quizQuestions[i].currSelected, budgetType],
        });
      } else if( i === VALUE_RANKING_INDEX ) {
        quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions: quizQuestions[i].ranking,
        });
      } else if(i === PICK_USED_CARS_INDEX ||
        i === PICK_NEW_CARS_INDEX) {
         const allFilledCars = quizQuestions[i].currSelected.filter((makeAndModelObj) => {
           return makeAndModelObj.make && makeAndModelObj.model;
         });
         quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions: allFilledCars,
        });
      }  else if (i === TRADE_IN_INFO_INDEX || i === IK_VEHICLE_INPUT_INDEX) {
        quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions: quizQuestions[i].currSelected,
        });
      } else if (i === USER_DETAILS_INDEX) {
        personalInformation = {
          firstName: quizQuestions[i].firstName,
          lastName: quizQuestions[i].lastName,
          phoneNo: quizQuestions[i].phoneNo,
          email: quizQuestions[i].email,
          preferredMethodOfContact: quizQuestions[i].preferredMethodOfContact,
        };
      } else {
        // eslint-disable-next-line no-loop-func
        quizQuestions[i].options.forEach((option) => {
          if (option.selected) {
            selectedOptions.push(option.text);
          }
        });
        quizSelections.push({
          question: quizQuestions[i].text,
          selectedOptions,
        });
      }
    }
    console.log(quizSelections, "quizSelections");

    const response = await createDealershipUser(
      quizSelections,
      personalInformation,
      dealershipId,
      userSource
    );

    clearDealershipUserSource()
    
    console.log(response, "response");
    navigate(`/${dealershipId}/matches?id=${response.userId}&email=0`);
  };

  useEffect(() => {
    quizComponentRef.current.scrollTop = 0;
  }, [currentQuestionIndex]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="quiz" ref={quizComponentRef}>
      <div className="quiz__filler">
        <div className="quiz__intro">
          <div className="quiz__intro--titles">
            <h2 className="header">
              Create Your Profile
            </h2>
            <QuizProgressBar count={count} active={currentQuestionIndex} />
            <h1 className="subheader">
              {quizQuestions[currentQuestionIndex].text}
              {/* {QuizFactsInfo[currentQuestionIndex] && <span className="info_svg_container" onClick={() => setQuizPopupOn(!quizPopupOn)}><Info /></span>} */}
            </h1>
            <h3 className="subtext">
              {quizQuestions[currentQuestionIndex].subtext}
            </h3>
          </div>
         
        </div>
        <div className={QuizFactsInfo[currentQuestionIndex] ? "question_divider_row":"question_divider_column"}>
          <div
            className={`${currentQuestionIndex === NEW_USED_INDEX || currentQuestionIndex == EV_SPECIFIC_Q1_INDEX || currentQuestionIndex ==  EV_SPECIFIC_Q2_INDEX
              ? "two_cards narrow_cards" : currentQuestionIndex === IKIDK_INDEX ? "two_wider_cards"
              : currentQuestionIndex === LIFESTYLE_INDEX ? "four_wider_cards": 
              QuizFactsInfo[currentQuestionIndex] || currentQuestionIndex===PAYMENT_MODE_INDEX || currentQuestionIndex === TRADE_IN_INDEX? "three_cards":"four_cards"} quiz__options_cards`
              }
          >
            <QuestionInterfaceSelector
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
            currentQuestionIndex={currentQuestionIndex}
          />
          </div>
          {(QuizFactsInfo[currentQuestionIndex] && quizPopupOn) && <QuizFact headText={QuizFactsInfo[currentQuestionIndex]?.headText} infoText={QuizFactsInfo[currentQuestionIndex]?.infoText} infoImg={QuizFactsInfo[currentQuestionIndex]?.img} 
        setQuizPopupOn={setQuizPopupOn} />}
          
          {(currentQuestionIndex === TRADE_IN_INDEX && (quizQuestions[TRADE_IN_INDEX].options[0].selected || quizQuestions[TRADE_IN_INDEX].options[2].selected)) && 
          <>
          <h4 className="option_header" style={{marginBlock : "0"}}>What vehicle will you be trading in?</h4>
          <div
            className="quiz__options_cards two_cards"
          >
          <TradeInInfoInput
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
          />
          </div>
        </>}
        {(currentQuestionIndex === IKIDK_INDEX && quizQuestions[IKIDK_INDEX].options[0].selected) && 
          <>
          <h4 className="option_header" style={{marginBlock : "0"}}>Which vehicle did you come in for?</h4>
          <div
            className="quiz__options_cards two_cards"
          >
          <IkVehicleInput
            quizQuestions={quizQuestions}
            setQuizQuestions={setQuizQuestions}
          />
          </div>
        </>}
        </div>
        <div className="quiz__footer">
          <div className="quiz__buttons">
            <div>
              <ButtonPlain
                border="none"
                padding="1.25rem 2.5rem"
                text="Back"
                onClick={prevQuestion}
              />
            </div>
            <div>
              {(!isSingleSelectionQuestion ||
                (isSingleSelectionQuestion &&
                  quizQuestions[currentQuestionIndex].visitedOnce)) && (
                <ButtonPlain
                  color={minimumSelected ? "#FFE6A9" : "#EEEEED"}
                  border="none"
                  padding="1.25rem 4.125rem"
                  text={currentQuestionIndex === USER_DETAILS_INDEX ? "Complete Check-In" : "Continue"}
                  onClick={minimumSelected ? nextQuestion : null}
                />
              )}
            </div>
          </div>
          <div className="badge__logo">
          <div style={{fontWeight : "400", marginRight:"0.75rem"}}>Powered By </div>
             <MaeCoreLogoYellow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardQuiz;
