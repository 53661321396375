export const AGED_INVENTORY = [
'W7L02629',
'W5L15376',
'W5L12377',
'W3L59605',
'W3L36914',
'W3L08953',
'W3L03625',
'W3L03553',
'W3L03453',
'W3L03111',
'W3L02694',
'W3L02546',
'W3L02469',
'W3L02398',
'W3L01605',
'W3B16840',
'W3B15714',
'W2L49377',
'W2L49241',
'W2L19301',
'W2L17814',
'W1L14935',
'W1E52317',
'W1E44096',
'W1E09206',
'W1E07749',
'U9N94680',
'U9N06942',
'U9N02690',
'U0E47120',
'U0E46685',
'U0E15289',
'R9D90108',
'R9C55600',
'R9C07934',
'R9B16631',
'R4L12884',
'R4L07960',
'K4S52747',
'K4S42604',
'K4K61344',
'K2A72112',
'K1R00746',
'E9B35162',
'E9A35441',
'E2B35722',
'E1A34773',
'8T105004',
'8R502267',
'8F150000'
]