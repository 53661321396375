import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { DEALERSHIP_CONFIG } from "../config/dealershipConfig";

const DealershipContext = createContext("dealershipName");

export const DealershipProvider = ({ children }) => {
  const [dealershipId, setDealershipId] = useState("camclarkford");
  const [dealershipName, setDealershipName] = useState(DEALERSHIP_CONFIG["camclarkford"].name);
  const [dealershipImgUrl, setDealershipImgUrl] = useState(DEALERSHIP_CONFIG["camclarkford"].imgUrl);
  const [linkMatchesToVDP, setLinkMatchesToVdp] = useState(DEALERSHIP_CONFIG["camclarkford"].linkMatchesToVDP);
  const [defaultInventoryImage, setDefaultInventoryImage] = useState(DEALERSHIP_CONFIG["camclarkford"].defaultInventoryImage);
  const location = useLocation();
  const navigate = useNavigate();
  const [userSource, setUserSource] = useState("Walk-In");

  useEffect(() => {
    const urlDealershipId = location.pathname.split('/')[1]; 
    if (urlDealershipId in DEALERSHIP_CONFIG) {
      setDealershipId(urlDealershipId);
      setDealershipName(DEALERSHIP_CONFIG[urlDealershipId].name)
      setDealershipImgUrl(DEALERSHIP_CONFIG[urlDealershipId].imgUrl)
      setLinkMatchesToVdp(DEALERSHIP_CONFIG[urlDealershipId].linkMatchesToVDP)
      setDefaultInventoryImage(DEALERSHIP_CONFIG[urlDealershipId].defaultInventoryImage)
      console.log("DEALERSHIP CONTEXT",urlDealershipId, DEALERSHIP_CONFIG[urlDealershipId].name, DEALERSHIP_CONFIG[urlDealershipId].imgUrl)
    } else {
      navigate('/')
    }

    const localStorageUserSource = localStorage.getItem("dealership-user-source");

    if (localStorageUserSource !== null) {
      setUserSource(localStorageUserSource);
    } else {
      const params = new URLSearchParams(location.search);
      const sourceParam = params.get("source");

      if (sourceParam) {
        setUserSource(sourceParam);
        localStorage.setItem("dealership-user-source", sourceParam);
      }
    }
    
  }, []);

  const clearDealershipUserSource = () => {
    localStorage.removeItem("dealership-user-source")
  }

  return (
    <DealershipContext.Provider
      value={{
        dealershipName,
        setDealershipName,
        dealershipImgUrl,
        dealershipId,
        linkMatchesToVDP,
        defaultInventoryImage,
        clearDealershipUserSource,
        userSource
      }}
    >
      {children}
    </DealershipContext.Provider>
  );
};

export const useDealershipContext = () => {
  return useContext(DealershipContext);
};
